<template>
  <div>
    <b-card border-variant="primary" v-show="this.totalRows === 0">
      <h3 class="text-black mb-2">Não há parcelas disponíveis para este contrato</h3>
      <b-card-text class="text-black mb-5"> Nenhuma parcela foi encontrada no cadastrado deste contrato. </b-card-text>
    </b-card>

    <b-table
      id="tabelaInformacoesAvancadas"
      v-show="totalRows"
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :current-page="pesquisar.currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #cell(parcela)="row">
        {{ row.item.parcela }}
      </template>
      <template #cell(valor)="row">
        {{ row.item.valorAntecipado ? formatarValor(row.item.valorAntecipado) : formatarValor(0) }}
      </template>
      <template #cell(amortizacao)="row">
        {{ row.item.amortizacao ? formatarValor(row.item.amortizacao) : formatarValor(0) }}
      </template>
      <template #cell(juros)="row">
        {{ row.item.juros && isFirstRow(row) == true ? formatarValor(jurosProRata) : formatarValor(0) }}
      </template>

      <template #cell(administracao)="row">
        {{
          row.item.proRataDecomposicao && row.item.proRataDecomposicao.administracao
            ? formatarValor(row.item.proRataDecomposicao.administracao)
            : formatarValor(0)
        }}
      </template>
      <template #cell(fundoGarantidor)="row">
        {{
          row.item.proRataDecomposicao && row.item.proRataDecomposicao.fundoGarantidor
            ? formatarValor(row.item.proRataDecomposicao.fundoGarantidor)
            : formatarValor(0)
        }}
      </template>
      <template #cell(indiceAtualizacao)="row">
        {{
          row.item.proRataDecomposicao && row.item.proRataDecomposicao.indiceAtualizacao
            ? formatarValor(row.item.proRataDecomposicao.indiceAtualizacao)
            : formatarValor(0)
        }}
      </template>

      <template #cell(jurosAtuarial)="row">
        {{
          row.item.proRataDecomposicao && row.item.proRataDecomposicao.jurosAtuarial
            ? formatarValor(row.item.proRataDecomposicao.jurosAtuarial)
            : formatarValor(0)
        }}
      </template>

      <template #cell(oscilacaoRiscos)="row">
        {{
          row.item.proRataDecomposicao && row.item.proRataDecomposicao.oscilacaoRiscos
            ? formatarValor(row.item.proRataDecomposicao.oscilacaoRiscos)
            : formatarValor(0)
        }}
      </template>
      <template #cell(taxaDeOportunidade)="row">
        {{
          row.item.proRataDecomposicao && row.item.proRataDecomposicao.taxaDeOportunidade
            ? formatarValor(row.item.proRataDecomposicao.taxaDeOportunidade)
            : formatarValor(0)
        }}
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
      <!-- pagination -->
      <b-pagination
        first-number
        last-number
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import vSelect from 'vue-select'
  import { VueAutosuggest } from 'vue-autosuggest'
  import { formatarValor, formatarData } from '@/libs/utils'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      vSelect,
      VueAutosuggest,
      formatarData,
    },
    directives: {
      Ripple,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      jurosProRata: {
        type: Number,
        default: 0,
      },
    },

    data() {
      return {
        isBusy: true,
        formatarValor,
        formatarData,
        number: {},
        suggestions: [],
        timeout: null,
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        required,

        //Padrão Tabela
        sortBy: '',
        totalRows: 1,
        sortDesc: false,
        sortDirection: 'asc',
        sortBy: 'parcelaAtual',
        pageOptions: [10, 25, 50, 100],
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 10, currentPage: 1 },
        striped: true,
        //Campos tabela
        fields: [
          {
            key: 'parcela',
            label: 'Nº da Parcela',
            sortable: true,
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'valor',
            label: 'Valor Total',
            sortable: true,
            variant: 'success',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'amortizacao',
            label: 'Amortização',
            sortable: true,
            variant: 'warning',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'juros',
            label: 'Custos',
            sortable: true,
            variant: 'warning',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'administracao',
            label: 'Taxa Administratação',
            sortable: true,
            variant: 'primary',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'fundoGarantidor',
            label: 'Fundo Garantidor',
            sortable: true,
            variant: 'primary',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'indiceAtualizacao',
            label: 'Índice Inflacionário',
            sortable: true,
            variant: 'primary',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'jurosAtuarial',
            label: 'Juros Atuarial',
            sortable: true,
            variant: 'primary',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'oscilacaoRiscos',
            label: 'Oscilação de Riscos',
            sortable: true,
            variant: 'primary',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'taxaDeOportunidade',
            label: 'Taxa de Oportunidade',
            sortable: true,
            variant: 'primary',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
        ],
        tabela: [],
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {},
    methods: {
      isFirstRow(data) {
        const parcelaItems = this.items[this.items.length - 1].parcela

        let parcelaTabela = data.item.parcelaAtual
        parcelaTabela = Number(data.item.parcelaAtual.split('/')[0])

        if (parcelaTabela == parcelaItems) {
          return true
        }
      },
    },
    watch: {
      items(newItems) {
        this.totalRows = newItems.length
        this.isBusy = false
      },
    },
  }
</script>

<style lang="scss">
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-success {
    background-color: #16a085 !important;
    color: white;
  }
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-warning {
    background-color: #f39c12 !important;
    color: white;
  }
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-primary {
    background-color: #2980b9 !important;
    color: white;
  }
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-success,
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-warning,
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-primary {
    color: white;
  }
</style>
