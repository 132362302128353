<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-table
          id="tabelaInformacoesParcelas"
          striped
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="isBusy"
        >
          <template #cell(valorAntecipado)="data">
            <!-- Verifica se é a última linha -->
            <span v-if="isFirstRow(data) == true"
              >{{ formatarValor(data.item.valorAntecipado) }}
              <b-badge pill>Pro rata: {{ formatarValor(jurosProRata) }}</b-badge>
            </span>
            <span v-else>{{ formatarValor(data.item.valorAntecipado) }} </span>
          </template>

          <template #cell(valor)="row">
            {{ formatarValor(row.item.valor) }}
          </template>
          <template #cell(vencimento)="row">
            {{ formatarData(row.item.vencimento) }}
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
          <b-pagination
            first-number
            last-number
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-card-body>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { formatarData, formatarValor } from '@/libs/utils'
  export default {
    components: {
      formatarValor,
      formatarData,
    },
    data() {
      return {
        perPage: 6,
        currentPage: 1,
        fields: [
          { key: 'parcelaAtual', label: 'Nº Parcela' },
          {
            key: 'valorAntecipado',
            label: 'Valor da Parcela',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          { key: 'vencimento', label: 'Vencimento', formatter: (value) => (value ? formatarData(value) : '') },
        ],
        sortBy: 'parcelaAtual',
        sortDesc: false,
        sortDirection: 'asc',
        isBusy: false,
        totalRows: 0,
        formatarValor,
        formatarData,
      }
    },
    props: {
      parametro: {},
      items: {},
      jurosProRata: 0,
    },
    async mounted() {},
    methods: {
      isFirstRow(data) {
        const parcelaItems = this.items[this.items.length - 1].parcela
        let parcelaTabela = data.item.parcelaAtual

        parcelaTabela = Number(data.item.parcelaAtual.split('/')[0])

        if (parcelaTabela == parcelaItems) {
          return true
        }
      },
    },
    watch: {
      items(newItems) {
        this.totalRows = newItems.length
      },
    },
  }
</script>
<style></style>
