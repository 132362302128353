<script>
  import Ripple from 'vue-ripple-directive'
  import docxtemplater from 'docxtemplater'
  import PizZip from 'pizzip'
  import JSZipUtils from 'jszip-utils'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData } from '@/libs/utils'
  import { formatarCompetencia } from '@core/utils/utils.js'
  import { generateImage } from '@/utils/image-module/image-module.helper.js'
  import { serializeDataIfNeeded } from '@/client/api/common'

  export default {
    components: {
      formatarData,
      generateImage,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
      dados: {},
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        images: JSON.parse(localStorage.getItem('images')),

        bancarioitems: [],
        formatarData,
        formatarCompetencia,
      }
    },
    mounted() {
      this.carregarGridBancaria()
    },
    methods: {
      carregarGridBancaria() {
        useJwt
          .get(`cadastro/instituto/bancarioInst/${this.userData.institutoSelecionado}`)
          .then((response) => {
            response.data.map((item) => {
              if (item.dadoBancarioContaOpcao == 'Entrada' || item.dadoBancarioContaOpcao == 'EntradaeSaida')
                this.bancarioitems = item
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      loadFile(url, callback) {
        JSZipUtils.getBinaryContent(url, callback)
      },
      renderDoc(dataVencimento) {
        const data = {
          idAntecipacao: this.dados.id ?? this.parametro.id,
          sigla: this.userData.Instituto.sigla,
          logo: this.images.logoMarca.logo,
          rpps: `${this.userData.Instituto.razaoSocial}` ?? 'Não informado',
          orgao: `${this.dados.nomeOrgao}` ?? 'Não informado',
          numeroDoContrato: `${this.dados.codigoContrato}` ?? 'Não informado',
          competencia: formatarCompetencia(this.dados.dadosParcelas[0].competencia) ?? 'Não informado',
          vencimentoGuia: formatarData(dataVencimento) ?? 'Não informado',
          cidadeInstituto: `${this.userData.Instituto.cidade}` ?? 'Não informado',
          ufInstituto: `${this.userData.Instituto.uf}` ?? 'Não informado',
          hoje: new Date() ?? 'Não informado',
          nometomador: `${this.parametro.nomeCompleto}` ?? 'Não informado',
          nomeResponsavelInstituto: `${this.userData.Instituto.nomeResponsavel}` ?? 'Não informado',
          cargoResponsavelInstituto: `${this.userData.Instituto.cargoResponsavel}` ?? 'Não informado',
          razaoSocialInstituto: `${this.userData.Instituto.razaoSocial}` ?? 'Não informado',
          valorGuia: `${
            this.dados.valorAntecipacao == null
              ? 'R$ 0,00'
              : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.dados.valorAntecipacao)
          }`,
          bancoInstituto: `${this.bancarioitems?.banco.nome}` ?? 'Não informado',
          agenciaInstituto:
            `${this.bancarioitems?.dadoBancarioAgencia} - ${this.bancarioitems?.dadoBancarioAgenciaDV}` ?? 'Não informado',
          contaInstituto:
            `${this.bancarioitems?.dadoBancarioConta} - ${this.bancarioitems?.dadoBancarioContaDV}` ?? 'Não informado',
          codigoOperacao: `${this.bancarioitems?.dadoBancarioContaOP}` ?? 'Não informado',
          observacao: this.dados.observacao ? `${this.dados.observacao}` : 'Não informado',
          rodape: `${this.userData.Instituto.logradouro}, ${this.userData.Instituto.numero}, ${this.userData.Instituto.bairro} - ${this.userData.Instituto.cep}, ${this.userData.Instituto.cidade}/${this.userData.Instituto.uf}, ${this.userData.Instituto.telefone} - ${this.userData.Instituto.razaoSocial}`,
        }
        const component = this
        this.loadFile(`/modeloGuiaAntecipacao.docx`, async function (error, content) {
          component.$message.info('Iniciando a geração da guia. Por favor, aguarde...')

          if (error) {
            console.error(error)
            throw error
          }

          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (error, key) {
                error[key] = value[key]
                return error
              }, {})
            }
            return value
          }

          function errorHandler(error) {
            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation
                })
                .join('\n')
              console.error(error)
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error
          }

          var zip = new PizZip(content)
          var doc
          try {
            doc = new docxtemplater(zip)
          } catch (error) {
            // Catch compilation errors (errors caused by the compilation of the template : misplaced tags)
            errorHandler(error)
          }

          doc.setData({
            cabecalho: data.cabecalho,
            rpps: data.rpps,
            orgao: data.orgao,
            numeroDoContrato: data.numeroDoContrato,
            competencia: data.competencia,
            vencimentoGuia: data.vencimentoGuia,
            cidadeInstituto: data.cidadeInstituto,
            ufInstituto: data.ufInstituto,
            hoje: data.hoje,
            nometomador: data.nometomador,
            nomeResponsavelInstituto: data.nomeResponsavelInstituto,
            cargoResponsavelInstituto: data.cargoResponsavelInstituto,
            razaoSocialInstituto: data.razaoSocialInstituto,
            valorGuia: data.valorGuia,
            bancoInstituto: data.bancoInstituto,
            agenciaInstituto: data.agenciaInstituto,
            contaInstituto: data.contaInstituto,
            codigoOperacao: data.codigoOperacao,
            observacao: data.observacao,
            rodape: data.rodape,
            sigla: data.sigla,
            idAntecipacao: data.idAntecipacao,
            logo: data.logo,
          })

          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render()
          } catch (error) {
            // Catch rendering errors (errors relating to the rendering of the template : angularParser throws an error)
            errorHandler(error)
          }

          // let out = await generateImage(data.log, doc)

          const out = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          }) //Output the document using Data-URI

          const formData = new FormData()
          formData.append('modulo', 'renegociacaoAntecipacao')
          formData.append('nomeArquivo', 'GuiaAntecipacao')
          formData.append('sigla', data.sigla)
          formData.append('file', out)
          formData.append('renegociacaoAntecipacaoId', data.idAntecipacao)

          await useJwt
            .postDocumento('/aws/uploadArquivoAWS', () => {}, formData)
            .then((response) => {
              if (response.data.status == 'erro') {
                console.error(response.data.mensagem)
                component.loading = false
                return
              } else {
                component.$message.success('Guia gerada com sucesso!')
                component.loading = false
                window.open(response.data.s3Url)
                component.$emit('fecharModal')
              }
            })
            .catch((error) => {
              console.error(error)
              component.$message.error('Erro ao gerar a Guia!')
              component.loading = false
              component.$emit('atualizarGrid')
              return
            })
        })
      },
    },
  }
</script>

<template></template>

<style></style>
